import {createApp} from 'vue'
import App from './App.vue'
import './assets/css/main.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'bootstrap/dist/css/bootstrap.css'
import axios from "axios";
import BootstrapVue3 from 'bootstrap-vue-3'
import {createPinia} from 'pinia'
import {createPersistedStatePlugin} from 'pinia-plugin-persistedstate-2'
import mitt from 'mitt';
import router from "@/router";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import moment from "moment";
import {vBTooltip} from "bootstrap-vue-3";

const pinia = createPinia()
moment().locale('it')
// create an .env.local file to store the local backend address. This will prevent the setting from being tracked on git
// for more environment variables, if you are using Vue cli 3, only variables that start with VUE_APP_ will be loaded.
// An env file for a specific mode (e.g. .env.production) will take higher priority than a generic one (e.g. .env).
const mainAxios = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_HOST
});

const dialogFlowAxios = axios.create({
    baseURL: 'https://dialogflow.googleapis.com/'
});

export {mainAxios, dialogFlowAxios};

dialogFlowAxios.interceptors.response.use(undefined, function (error) {
    if (error.response) {
        if (error.response.data.error.code === 401) {
            useAuthStore().$reset()
            useAuthStore().errorToken = 'Sessione scaduta'
            router.push('/login').catch(() => {
            })
            return Promise.reject(error);
        }
    }
    return Promise.reject(error);
})

mainAxios.interceptors.response.use(undefined, function (error) {
    if (error.response) {
        if (error.response.status === 401) {
            useAuthStore().$reset()
            let string_split
            Object.keys(error.response.data).forEach(el => {
                string_split = el.split('-')
                if (string_split[1] === 'expired_token') {
                    useAuthStore().errorToken = 'Sessione scaduta'
                } else if (string_split[1] === 'invalid_token') {
                    useAuthStore().errorToken = 'Rieffettuare il login'
                } else if (el === 'code-invalid_code') {
                    useAuthStore().errorToken = error.response.data['code-invalid_code']
                } else if (el === 'authorization-user_inactive') {
                    useAuthStore().errorToken = error.response.data['authorization-user_inactive']
                } else if (el === 'detail') {
                    useAuthStore().errorToken = error.response.data['detail']
                } else if (el === 'authorization-expired_token') {
                    useAuthStore().errorToken = error.response.data['authorization-expired_token']
                }
            })
            router.push('/login').catch(() => {
            })
            return Promise.reject(error);
        }
    }
    if (error.message === 'Network Error') {
        useApiSettingStore().overlayModal = false
        useApiSettingStore().errorNetwork = true
    }
    return Promise.reject(error);
})


export function calculateMargin() {
    if (useAuthStore().isAuthenticated && !useApiSettingStore().hamburgerStatusGetters) {
        // prendo il container che ha all'interno le notifiche e il contenuto centale
        let container_content_app = document.getElementsByClassName('container-content-app')[0]
        // prendo l'elemento sidebar
        let container_sidebar = document.getElementsByClassName('container-sidebar')[0]
        // setto il margine sinistro al container di destra rispetto al sidebar menu
        if (container_sidebar && container_content_app) {
            container_content_app.style.marginLeft = container_sidebar.getBoundingClientRect().width + 'px'
        } else {
            container_content_app.style.marginLeft = 0 + 'px'
        }
    } else {
        let container_content_app = document.getElementsByClassName('container-content-app')[0]
        container_content_app.style.marginLeft = 0 + 'px'
    }
}

const emitter = mitt();

const app = createApp(App)

app.directive('b-tooltip',vBTooltip)

app.use(BootstrapVue3)
app.use(router)
app.config.globalProperties.emitter = emitter
app.use(pinia)
pinia.use(createPersistedStatePlugin())
app.mount('#app')
