import {defineStore} from 'pinia'
import {mainAxios} from "@/main";


export const useApiSettingStore = defineStore({
    id: 'apiSettings',

    state: () => ({
        apiSettings: null,
        objectToDelete: null,
        error500: '',
        errorNetwork: false,
        spinnerDeleting: false,
        overlayPopup: false,
        typePopup: '',
        overlayModal: false,
        confirmDelete: false,
        messageToDelete: '',
        typeObjectToDelete: '',
        hamburgerStatus: false,
        searchFilter: '',
        currentPageFilter: 1,
        indexDocToDelete: -1
    }),
    actions: {
        resetConfirmDelete() {
            this.objectToDelete = null
            this.confirmDelete = false
            this.messageToDelete = ''
            this.indexDocToDelete = -1
            this.typeObjectToDelete = ''
        },
        getApiSettings() {
            return new Promise((resolve, reject) => {
                mainAxios.options('api/')
                    .then(res => {
                        this.apiSettings = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },

    getters: {
        apiSettingsGetters: state => {
            return state.apiSettings
        },
        error500Getters: state => {
            return state.error500
        },
        errorNetworkGetters: state => {
            return state.errorNetwork
        },
        overlayModalGetters: state => {
            return state.overlayModal
        },
        overlayPopupGetters: state => {
            return state.overlayPopup
        },
        typePopupGetters: state => {
            return state.typePopup
        },
        confirmDeleteGetters: state => {
            return state.confirmDelete
        },
        messageToDeleteGetters: state => {
            return state.messageToDelete
        },
        objectToDeleteGetters: state => {
            return state.objectToDelete
        },
        typeObjectToDeleteGetters: state => {
            return state.typeObjectToDelete
        },
        hamburgerStatusGetters: state => {
            return state.hamburgerStatus
        },
        searchFilterGetters: state => {
            return state.searchFilter
        },
        currentPageFilterGetters: state => {
            return state.currentPageFilter
        },
        indexDocToDeleteGetters: state => {
            return state.indexDocToDelete
        },
        spinnerDeletingGetters: state => {
            return state.spinnerDeleting
        }
    }
})


